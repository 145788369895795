import React from "react";
import { Link } from "react-router-dom";

const GitHubLink = ({ repo, text }) => {
  return (
    <Link
      to={repo}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="tag gitHub">
        {text ? (
          <span className="tagText">{text}</span>
        ) : (
          <span className="tagText">View on GitHub</span>
        )}
      </div>
    </Link>
  );
};

export default GitHubLink;
